<template>
  <div>
    <form id="payment-form">
      <v-row
        v-show="!userHasPaymentMethod"
        no-gutters
      >
        <v-col cols="12">
          <div class="mb-2">
            <v-text-field
              id="card-holder-address"
              v-model="defaultAddress"
              placeholder="Address"
              :rules="[rules.required]"
              :readonly="userHasPaymentMethod"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <div class="mb-2">
            <v-text-field
              id="card-holder-city"
              v-model="defaultCity"
              placeholder="City"
              :rules="[rules.required]"
              :readonly="userHasPaymentMethod"
            />
          </div>
        </v-col>
        <v-col cols="7">
          <div class="mb-2 pr-2">
            <v-text-field
              id="card-holder-state"
              v-model="defaultState"
              placeholder="State"
              :rules="[rules.required]"
              :readonly="userHasPaymentMethod"
            />
          </div>
        </v-col>
        <v-col cols="5">
          <div class="mb-2">
            <v-text-field
              id="card-holder-postalcode"
              v-model="defaultPostalCode"
              placeholder="ZIP"
              :rules="[rules.required]"
              :readonly="userHasPaymentMethod"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <div class="mb-2">
            <v-text-field
              id="card-holder-name"
              v-model="defaultNameOnCard"
              placeholder="Name on card"
              :rules="[rules.required]"
              :readonly="userHasPaymentMethod"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <div class="stripe-field mb-2">
            <label>
              <div
                id="card-number"
                class="form-control"
              />
            </label>
          </div>
        </v-col>
        <v-col
          cols="7"
          class="pr-2"
        >
          <div class="stripe-field mb-2">
            <label>
              <div
                id="card-cvc"
                class="form-control"
              />
            </label>
          </div>
        </v-col>
        <v-col cols="5">
          <div class="stripe-field mb-2">
            <label>
              <div
                id="card-exp"
                class="form-control"
              />
            </label>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-show="userHasPaymentMethod"
        no-gutters
        class="px-4 py-3"
      >
        <v-col
          cols="12"
          class="mb-2"
        >
          <div class="text text-m-small-regular label-default-payment">
            Name
          </div>
          <div class="text text-m-large-regular value-default-payment">
            {{ defaultNameOnCard }}
          </div>
        </v-col>
        <v-col
          cols="12"
          class="mb-2"
        >
          <div class="text text-m-small-regular label-default-payment">
            Address
          </div>
          <div class="text text-m-large-regular value-default-payment">
            {{ defaultFullAddress }}
          </div>
        </v-col>
        <v-col
          cols="12"
          class="mb-2"
        >
          <div class="text text-m-small-regular label-default-payment">
            Card number
          </div>
          <div class="text text-m-large-regular value-default-payment">
            {{ defaultCardNumber }}
          </div>
        </v-col>
        <v-col
          cols="12"
          class="mb-2"
        >
          <div class="text text-m-small-regular label-default-payment">
            Expiration date
          </div>
          <div class="text text-m-large-regular value-default-payment">
            {{ defaultCardDate }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            v-show="userHasPaymentMethod"
            block
            color="primary"
            ripple
            depressed
            outlined
            class="btn-submit mb-3"
            :loading="loadingDeleteCard"
            :disabled="loadingDeleteCard"
            @click="changePaymentMethod"
          >
            Change payment method
          </v-btn>
          <v-btn
            v-show="!userHasPaymentMethod"
            id="first-time-payment-button"
            block
            color="primary"
            ripple
            depressed
            class="btn-submit"
            :loading="loadingSubmit"
            :disabled="loadingSubmit"
          >
            Submit Payment
          </v-btn>
          <v-btn
            v-show="userHasPaymentMethod"
            id="default-payment-method-button"
            block
            color="primary"
            ripple
            depressed
            class="btn-submit"
            :loading="loadingSubmit"
            :disabled="loadingSubmit"
            @click="payWithDefaultPaymentMethod"
          >
            Submit Payment
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import ValidatorComponent from '@/components/common/ValidatorComponent';
import { loadStripe}  from '@stripe/stripe-js';
import { mapActions, mapGetters } from 'vuex';
export default {
  data: () => ({
    defaultAddress: null,
    defaultCity: null,
    defaultState: null,
    defaultPostalCode: null,
    defaultNameOnCard: null,
    defaultCardNumber: null,
    defaultCardDate: null,
    loadingSubmit: false,
    loadingDeleteCard: false,
    deleteOldPaymentMethod: false,
    hideDefaultPaymentMethod: false,
    stripe: null,
    intentClientSecret: null,
    rules: ValidatorComponent.data().rules,
    setupIntentSucceeded: false,
    setupIntentResponse: null,
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('profile', ['currentPaymentMethod']),
    userHasPaymentMethod() {
      return this.currentPaymentMethod !== null && this.currentPaymentMethod !== undefined && this.currentPaymentMethod.billing_details !== undefined && !this.hideDefaultPaymentMethod;
    },
    defaultFullAddress() {
      return `${this.defaultAddress}. ${this.defaultCity}, ${this.defaultState} ${this.defaultPostalCode}`;
    },
  },
  props: ['itemSelected'],
  watch: {
    currentPaymentMethod: {
      handler: function () {
        if (this.currentPaymentMethod?.billing_details) {
          this.defaultNameOnCard = this.currentPaymentMethod.billing_details.name;
          this.defaultAddress = this.currentPaymentMethod.billing_details.address.line1;
          this.defaultCity = this.currentPaymentMethod.billing_details.address.city;
          this.defaultState = this.currentPaymentMethod.billing_details.address.state;
          this.defaultPostalCode = this.currentPaymentMethod.billing_details.address.postal_code;
          this.defaultCardNumber = '••••••••'+this.currentPaymentMethod.card?.last4;
          this.defaultCardDate =  `${ this.currentPaymentMethod.card?.exp_month }`.padStart(2, '0') + ` / ${ this.currentPaymentMethod.card?.exp_year }`;
        }
      }
    }
  },
  methods: {
    ...mapActions('subscriptions', ['getPaymentIntent', 'postConfirmSubscription']),
    ...mapActions('profile', ['deletePaymentMethod', 'addPaymentMethod']),
    showError(fieldError) {
      this.$toasted.global.showError({message: fieldError});
    },
    validateCustomField(fieldValidationResult) {
      return fieldValidationResult === true;
    },
    getSetupPaymentIntent() {
      this.getPaymentIntent().then( response => {
        this.intentClientSecret = response.data.intent.client_secret;
      });
    },
    payWithDefaultPaymentMethod() {
      this.loadingSubmit = true;
      this.confirmPayment(this.currentPaymentMethod.id, this.itemSelected.priceHash);
    },
    confirmPayment(paymentMethod, item) {
      this.postConfirmSubscription({ paymentMethod, item })
      .then( response => {
        this.$emit('payment-success', response.data);
        this.$toasted.global.showSuccess({ message: response.data.message });
        this.loadingSubmit = !this.loadingSubmit;
      })
      .catch( error => {
        this.$toasted.global.showError({message:  error.response.data ?  error.response.data.message : 'Unexpected error'});
        this.loadingSubmit = !this.loadingSubmit;
        this.setupIntentSucceeded = false;
        this.getSetupPaymentIntent();
      });
    },
    setupFirstTimePayment(card) {

      const cardHolderName = document.querySelector('#card-holder-name');
      const cardHolderAddress = document.querySelector('#card-holder-address');
      const cardHolderCity = document.querySelector('#card-holder-city');
      const cardHolderState = document.querySelector('#card-holder-state');
      const cardHolderPostalCode = document.querySelector('#card-holder-postalcode');
      const cardButton = document.querySelector('#first-time-payment-button');

      cardButton.addEventListener('click', async (e) => {
        e.preventDefault();

        if (this.deleteOldPaymentMethod) {
          await this.deleteCard();
        }

        const validateName = ValidatorComponent.data().rules.names(cardHolderName.value);
        const validateAddress = ValidatorComponent.data().rules.paymentCardField(cardHolderAddress.value, cardHolderAddress.placeholder);
        const validateCity = ValidatorComponent.data().rules.paymentCardField(cardHolderCity.value, cardHolderCity.placeholder);
        const validateState = ValidatorComponent.data().rules.paymentCardField(cardHolderState.value, cardHolderState.placeholder);
        const validatePostalCode = ValidatorComponent.data().rules.paymentCardField(cardHolderPostalCode.value, cardHolderPostalCode.placeholder);

        if(!this.validateCustomField(validateName)) {
          this.showError(validateName);
        } else

        if(!this.validateCustomField(validateAddress)) {
          this.showError(validateAddress);
        } else

        if(!this.validateCustomField(validateCity)) {
          this.showError(validateCity);
        } else

        if(!this.validateCustomField(validateState)) {
          this.showError(validateState);
        } else

        if(!this.validateCustomField(validatePostalCode)) {
          this.showError(validatePostalCode);
        } else {

          this.loadingSubmit = !this.loadingSubmit;

          if(!this.setupIntentSucceeded) {
            const { setupIntent, error } = await this.stripe.confirmCardSetup(
                this.intentClientSecret, {
                    payment_method: {
                        card,
                        billing_details: 
                        { 
                          name: cardHolderName.value,
                          address: { 
                            line1: document.querySelector('#card-holder-address').value,
                            city: document.querySelector('#card-holder-city').value,
                            state: document.querySelector('#card-holder-state').value,
                            postal_code: document.querySelector('#card-holder-postalcode').value,
                          },
                        }
                    }
                }
            );
            this.setupIntentResponse = setupIntent;

            if (error) {
              this.$toasted.global.showError({message:  error.message });
              this.loadingSubmit = !this.loadingSubmit;
            } else {
              this.setupIntentSucceeded = true;
            }
          }

          if (this.setupIntentSucceeded) {

            if(this.deleteOldPaymentMethod || (!this.currentPaymentMethod?.billing_details && this.currentUser.stripe_id)) {
              await this.addPaymentMethod({ paymentMethodHash: this.setupIntentResponse.payment_method });
            }

            this.confirmPayment(this.setupIntentResponse.payment_method, this.itemSelected.priceHash);
          }
        }
      });
    },
    changePaymentMethod() {
      this.deleteOldPaymentMethod = true;
      this.hideDefaultPaymentMethod = true;
    },
    async deleteCard() {

      this.loadingDeleteCard = true;
      const response = await this.deletePaymentMethod();

      if(response) {
        this.$toasted.global.showSuccess({message: response.message});
      } else {
        this.$toasted.global.showError({message:  response.data ?  response.data.message : 'Unexpected error'});
      }

      this.loadingDeleteCard = false;
    }
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

    let elements = this.stripe.elements();

    var style = {
        base: {
            'lineHeight': '24px',
            'fontSize': '16px',
            'color': '#495057',
            '::placeholder': {
              'fontSize': '16px',
              'fontWeight': 'normal',
              'lineHeight': '24px',
              'letterSpacing': '0.5px',
              'color': '#BDBDBD',
            },
        },
    };

    // Card number
    var card = elements.create('cardNumber', {
        'placeholder': 'Card number',
        'style': style,
        'showIcon': true,
    });
    card.mount('#card-number');

    // CVC
    var cvc = elements.create('cardCvc', {
        'placeholder': 'CVC',
        'style': style,
        'showIcon': true,
    });
    cvc.mount('#card-cvc');

    // Card expiry
    var exp = elements.create('cardExpiry', {
        'placeholder': 'MM/YY',
        'style': style,
        'showIcon': true,
    });
    exp.mount('#card-exp');

    this.getSetupPaymentIntent();

    this.setupFirstTimePayment(card);
  }
}
</script>

<style>
.stripe-field .__PrivateStripeElement {
  height: 20px;
}
input.form-control {
  font-style: normal;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}
div.form-control {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.v-input__control > .v-input__slot::before {
    border-color: #ccc !important;
}
.form-control::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #BDBDBD;
  opacity: 1;
}
.form-control:focus {
  outline: none;
}
.btn-submit {
  border-radius: 10px;
}
.label-default-payment {
  color: #BDBDBD;
}
</style>
