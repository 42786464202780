<template>
  <div>
    <PaymentFormComponent
      :item-selected="itemSelected"
      @payment-success="subscriptionSuccess"
    />
  </div>
</template>

<script>
import PaymentFormComponent from "@/components/payment/PaymentFormComponent";
import { mapActions } from 'vuex';
export default {
  components: { PaymentFormComponent },
  props: ['itemSelected'],
  methods: {
    ...mapActions('profile', ['getUserPaymentMethod']),
    subscriptionSuccess(payload) {
      this.$emit('payment-success', payload);
    }
  },
  mounted() {
    this.getUserPaymentMethod();
  }
}
</script>
