<script>
export default {
    data: () => ({
        rules: {
            required: v => !!v || 'This field is required.',
            email: v => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(v) || 'Invalid e-mail.'
            },
            password: v => {
                if ( !(v && v.length >= 8) ) return 'Password must have at least 8 characters'
                if ( v && v.length > 128 ) return 'Password must have less than 128 characters'
                if ( !/(?=.*[A-Z])/.test(v) ) return 'Must have one uppercase character'
                if ( !/(?=.*[a-z])/.test(v) ) return 'Must have one lowercase character'
                if ( !/(?=.*\d)/.test(v) ) return 'Must have one number'
                if ( !/([~`^&*()\-_+={}[\]|/\\:;"'<>,.?!@#$%])/.test(v) ) return 'Must have one special character [~`^&*()-_+={}[]|/\\:;"\'<>,.?!@#$%]'
                else return true;
            },
            phoneNumber: v => {
                if (v) {
                    if (v && !/^\([2-9][0-9][0-9]\)$/.test(v.split(' ')[0])) return 'Invalid US area code.'
                    if (v && !/^\([2-9][0-9][0-9]\) [0-9]{3}-[0-9]{4}$/.test(v)) return 'Invalid phone number.'
                    else return true;
                }
                return false;
            },
            onlyOneNumber: v => {
                const pattern = /^[0-9]$/
                return pattern.test(v) || 'Invalid number.'
            },
            fullCode: v => {
                if ( !(v && v.length == 6) ) return 'Code must be 6 characters long'
                else return true;
            },
            names: v => {
                if ( !(v && v.length <= 30) ) return 'Please enter only the first 30 characters of your name.'
                const pattern = /^[A-Z \-a-z']+$/
                return pattern.test(v) || 'Invalid first name: only letters, one space, simple dash or apostrophe.'
            },
            lastName: v => {
                if ( !(v && v.length <= 30) ) return 'Please enter only the first 30 characters of your name.'
                const pattern = /^[A-Z \-a-z']+$/
                if (!pattern.test(v)) return 'Invalid last name: only letters, one space, simple dash or apostrophe.'
            },
            pubmedId: v => {
                if ( v == null ) return false;
                if ( v && v.length > 30 ) return 'Please, enter only less than 30 characters'

                const pattern = /[0-9]+$/
                return pattern.test(v) || 'Invalid ID.'
            },
            notNullUndefinedOrEmpty: v => {
                return v !== null && v !== undefined && v !== '';
            },
            keyword: v => {
                if(v !== null && v !== '' && v !== undefined){
                    if (v.length >= 30) return 'Please enter shorter keywords.'
                    const pattern = /^[A-Z \-a-z0-9]+$/
                    return pattern.test(v) || 'The keyword contain invalid characters.'
                }
                return 'You can\'t add empty keywords.'
            },
            number: v => {
                return (!isNaN(parseFloat(v)) && isFinite(v) || 'The price must be a valid number');
            },
            priceLessThanTen: v => {
                if ( !(v &&   v <  11) ) return 'Price can\'t be bigger than ten dollars'
                else return true;
            }          ,
            priceNumberPositive: v => {
                if ( (v && v <= -1  ) ) return 'Price can\'t be less than zero dollars'
                else return true;
            },
            paymentCardField: (v,fieldName)=> {
                if(v !== null && v !== '' && v !== undefined){
                    return true;
                }
                return `${fieldName} can't be empty`
            },
        }
    }),
}
</script>
