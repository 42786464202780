<template>
  <div :class="[identifierClass, {'my-8': !isFullVisible }]">
    <v-card
      class="px-4 pt-5 pb-3 card-box-plan"
      :class="{'card-box-plan-selected': isCardBoxPlanSelected }"
    >
      <div
        v-show="plan.id === enumPlans.professional"
        class="text text-m-small-regular headband headband-text"
      >
        Save 20%
      </div>
      <v-row no-gutters>
        <v-col cols="12">
          <v-row
            no-gutters
            class="text-center justify-center"
          >
            <v-col
              cols="12"
              class="mb-2"
            >
              <h6
                class="heading heading-m-small"
                :class="{'plan-title-headband' : plan.id === enumPlans.professional }"
              >
                {{ plan.title }}
              </h6>
            </v-col>
            <v-col
              cols="12"
              class="mb-2"
            >
              <span class="heading display-small plan-price-big">${{ plan.priceMonthly }}</span>
            </v-col>
            <v-col
              v-show="plan.billedAnually"
              cols="12"
              class="mb-2"
            >
              <span class="text text-l-large-regular plan-price-monthly-preview">Monthly</span>
            </v-col>
            <v-col
              v-show="plan.billedAnually"
              cols="12"
              class="mb-4"
            >
              <span class="text text-l-small-regular plan-price-billed-preview">Billed anually at ${{ plan.billedAnually }}</span>
            </v-col>
            <v-col class="col-10 col-lg-8 mb-2">
              <div class="plan-price-pill-preview">
                <span class="text text-m-small-regular plan-price-pill-text-preview">{{ plan.period }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="mt-5"
            justify="center"
          >
            <v-col
              v-for="benefit,keyBullet in plan.benefits"
              :key="keyBullet+uniqueKeysHelper()"
              class="d-flex align-start mt-3"
              cols="11"
            >
              <v-icon
                color="primary"
                class="mr-2"
              >
                mdi-check
              </v-icon>
              <span class="text text-l-small-regular plan-bullet-text">{{ benefit }}</span>
            </v-col>
            <v-col
              v-for="limitation,keyBullet in plan.limitations"
              :key="keyBullet+uniqueKeysHelper()"
              class="d-flex align-start mt-3"
              cols="11"
            >
              <v-icon
                color="secondary"
                class="mr-2"
              >
                mdi-close
              </v-icon>
              <span class="text text-l-small-regular plan-bullet-text">{{ limitation }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-show="currentPlanCancelledAt && plan.priceHash === currentPlan"
        no-gutters
        class="mt-3"
      >
        <v-col cols="12">
          <span class="text text-m-large-regular dialog-text-small red--text">Your grace period for this plan ends at <strong>{{ currentPlanEndAt }}</strong></span>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="mt-7"
      >
        <v-col
          v-show="shouldShowButtonToUpgradeOrDowngrade"
          cols="12"
          class="align-self-end"
        >
          <v-btn
            block
            color="primary"
            ripple
            depressed
            class="btn-choose-plan"
            @click="selectPlan(plan)"
          >
            {{ upgradeOrDowngradeText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="showDialog"
      :max-width="360"
      persistent
    >
      <v-card class="theme-dialog error-dialog-size">
        <v-row
          no-gutters
          justify="center"
          class="pt-5"
        >
          <v-col
            cols="5"
            class="text-center"
          >
            <div class="error-icon-style-answer">
              <v-icon class="red--text">
                mdi-alert-circle-outline
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-card-title class="text text-m-large-regular dialog-text-title text-center pt-2 mb-3">
          Downgrading plan
        </v-card-title>

        <v-card-text class="pb-0 px-3">
          <v-row
            no-gutters
            class="mb-3"
          >
            <v-col
              cols="12"
              class="d-flex text-center justify-center align-center"
            >
              <div class="d-flex align-center">
                <v-sheet class="card-comparison-current-plan card-comparison-plan pa-3">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <div class="text text-m-large-bold card-comparison-title mb-2">
                        Current plan
                      </div>
                      <div class="text text-l-large-bold card-comparison-plan-name mb-3">
                        {{ currentPlanName }}
                      </div>
                      <div>
                        <v-chip class="text text-m-small-regular card-comparison-date">
                          UNTIL {{ currentPlanEndAt }}
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-sheet>
                <div>
                  <ArrowRightThinComponent />
                </div>
              </div>
              <div>
                <v-sheet class="card-comparison-plan px-3 py-5">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <div class="text text-m-large-bold card-comparison-title mb-2">
                        New plan
                      </div>
                      <div class="text text-l-large-bold card-comparison-plan-name mb-3">
                        {{ plan.title }}
                      </div>
                      <div>
                        <v-chip class="text text-m-small-regular card-comparison-date">
                          UNTIL {{ currentPlanEndAt }}
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-sheet>
              </div>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="mb-3"
          >
            <v-col cols="12">
              <p class="text text-m-large-regular dialog-text-small black--text mb-0">
                Are you sure you want to downgrade your plan from {{ currentPlanName }} to {{ plan.title }}?
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-row no-gutters>
            <v-col
              cols="6"
              class="pr-1"
            >
              <v-btn 
                :loading="loadingDowngradeRequest"
                :disabled="loadingDowngradeRequest"
                block
                class="text-capitalize"
                elevation="0"
                color="default"
                @click="confirmDowngradePlan"
              >
                Yes
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              class="pl-1"
            >
              <v-btn
                block
                class="text-capitalize"
                elevation="0"
                color="primary"
                @click="showDialog = false"
              >
                No
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ArrowRightThinComponent from "@/assets/ArrowRightThinComponent";
import { v4 as uuidv4 } from 'uuid';
import { mapActions } from 'vuex';
export default {
  components: { ArrowRightThinComponent },
  props: ['plan', 'isUserFreePlan', 'currentPlan', 'currentPlanName', 'currentPlanEndAt', 'currentPlanCancelledAt', 'pricesHash'],
  data: () => ({
    uniqueKeysHelper: uuidv4,
    isFullVisible: false,
    showDialog: false,
    loadingDowngradeRequest: false,
    enumPlans: { free: 0, standard: 1, professional: 2 }
  }),
  computed: {
    identifierClass() {
      return this.plan ? `item-container-${this.plan.id}` : '';
    },
    gracePeriodEndsAt() {
      return this.currentPlanEndAt;
    },
    upgradeOrDowngradeText() {
      return (this.isUserFreePlan && this.plan.id !== this.enumPlans.free)
      || (!this.isUserFreePlan && this.plan.id === this.enumPlans.standard && this.plan.priceHash === this.currentPlan)
      || (!this.isUserFreePlan && this.plan.id === this.enumPlans.professional && this.plan.priceHash !== this.currentPlan)
      || ( this.currentPlanCancelledAt )
       ? 'Upgrade plan' : 'Downgrade plan';
    },
    isCardBoxPlanSelected() {
      return this.plan.priceHash === this.currentPlan;
    },
    shouldShowButtonToUpgradeOrDowngrade() {
      if (this.plan.priceHash) {
        if (this.plan.priceHash !== this.currentPlan) {
          return true;
        }
        if( this.plan.priceHash === this.currentPlan && this.currentPlanCancelledAt ) {
          return true;
        }
      } else {
        if (this.currentPlan && !this.currentPlanCancelledAt) {
          return true;
        }
      }

      return false;
    }
  },
  methods: {
    ...mapActions('subscriptions', ['postConfirmDowngradeSubscription']),
    selectPlan(plan) {
      if ( this.currentPlan && plan.id !== this.enumPlans.professional ) {
        this.showDialog = true;
      } else {
        this.$emit('select-plan', plan);
      }
    },
    async confirmDowngradePlan() {

      this.loadingDowngradeRequest = true;
      const response = await this.postConfirmDowngradeSubscription({ item: this.plan.priceHash });

      if(response) {
        this.$toasted.global.showSuccess({message: response.message});
        this.$emit('change-success');
      } else {
        this.$toasted.global.showError({message:  response.data ?  response.data.message : 'Unexpected error'});
      }

      this.loadingDowngradeRequest = false;
      this.showDialog = false;
    }
  },
  async mounted() {
    await this.$nextTick();
    let self = this;
    var observer = new IntersectionObserver(function(entries) {
      self.isFullVisible = entries[0].isIntersecting === true;
    }, { threshold: [1] });

    observer.observe(document.querySelector(".item-container-"+self.plan.id));
  }
}
</script>
<style scoped>
.v-card.card-box-plan {
  display: flex;
  flex-direction: column;
}
.headband {
  display: flex;
  height: 0;
  width: 100px;
  opacity: 1;
  border-bottom: 20px solid #F5F5F5;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-radius: unset !important;
  justify-content: center;
  transform: rotate(45deg);
  position: relative;
  margin-right: -39px;
  margin-left: auto;
  margin-top: -3px;
}
.headband-text {
  color: #1DA1F2;
}
.plan-title-headband {
  margin-top: -20px;
}
.theme-dialog {
  background: #FFFFFF;
  box-shadow: none !important;
  border-radius: 15px !important;
}
.dialog-text-small {
  text-align: center;
}
.dialog-text-title {
  text-align: center;
  justify-content: center;
}
.v-dialog {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12) !important;
  border-radius: 15px !important;
}
.error-icon-style-answer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 82, 82, 0.15);
  color: #FF5252;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  margin: auto;
}
.card-comparison-current-plan {
  border: 1px solid #1DA1F2;
}
.card-comparison-plan {
  min-width: 142px;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
}
.card-comparison-title {
  color: #757575;
}
.card-comparison-plan-name {
  color: #1DA1F2;
}
.card-comparison-date {
  color: #1DA1F2;
  background: #D2ECFC !important;
  border-radius: 12px !important;
}
</style>
