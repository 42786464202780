<template>
  <div>
    <v-btn
      v-if="!btnStyle"
      class="btn-share"
      icon
      @click="shareLink"
    >
      <v-icon>mdi-share-outline</v-icon>
      <span>{{ shareCounter }}</span>
      <slot name="content" />
    </v-btn>
    <v-btn
      v-if="btnStyle"
      block
      color="primary"
      ripple
      depressed
      class="btn-radius responsive-btn-block"
      @click="shareLink" 
    >
      <span>{{ shareCounter }}</span>
      <slot name="content" />
    </v-btn>
    <v-btn
      v-if="enableCopyToClipboard"
      icon
      @click="copyToClipboard"
    >
      <v-icon>mdi-content-copy</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: ['title', 'description', 'url', 'initialShareCounter', 'enableCopyToClipboard', 'btnStyle'],
  data: () => ({ 
    screenSize: { desktop: "1024" }
  }),
  computed: {
    shareCounter() {
      return this.initialShareCounter !== null ? this.initialShareCounter : '';
    }
  },
  methods: {
    shareLink: async function () {
      try {
        const shareData = {
          title: this.title,
          text: this.description,
          url: this.url,
        }

        if(this.$vuetify.breakpoint.width >= this.screenSize.desktop) {
          return this.copyToClipboard();
        }

        if (!navigator.share) {
          return this.$toasted.global.showError({message: 'The navigator does not support web shared API'});
        }

        if (navigator.share) {
          return await navigator.share(shareData)
        }
      } 
      catch (error) {
        this.$toasted.global.showError({message: error.response.data?.message || error.message});
      }
    },
    notifyShare() {
      this.$emit('notify-share');
    },
    copyToClipboard() {
      var aux = document.createElement("input");
      aux.setAttribute("value", this.url);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$toasted.global.showSuccess({message: 'Referral link copied to clipboard!'});
    }
  },
}
</script>
<style scoped>
.btn-share {
  width: auto !important;
}
@media (min-width: 960px) {
  .responsive-btn-block {
    min-width: auto !important;
  }
}
</style>
