<template>
  <div class="row justify-center no-gutters">
    <v-col cols="12">
      <v-row
        v-show="finishInitLoad"
        class="view-container justify-center mt-0 mt-lg-6"
      >
        <v-col
          v-show="!planSelected && !isShowingPlans"
          cols="12"
          sm="7"
          lg="6"
          class="mb-6"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <v-card class="card-box-plan responsive-box pa-4">
                <v-row
                  no-gutters
                  justify="center"
                  class="my-auto"
                >
                  <v-col
                    cols="11"
                    class="mb-4 text-center"
                  >
                    <h6 class="heading heading-m-small mb-2">
                      My plan
                    </h6>
                    <h6 class="heading heading-m-large plan-current">
                      {{ userSubcriptionPlanName }}
                    </h6>
                  </v-col>
                  <v-col
                    v-show="currentPlan"
                    cols="12"
                    class="sheet-panel text-center py-2 mb-4"
                  >
                    <span
                      v-show="!userSubscriptionCancelledAt"
                      class="plan-current-subtitle"
                    >Subscription renewal date</span>
                    <span
                      v-show="userSubscriptionCancelledAt"
                      class="plan-current-subtitle red--text"
                    >Your grace period ends on</span>
                    <p class="font-weight-bold mb-0">
                      {{ userSubscriptionEndAt }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      color="primary"
                      ripple
                      depressed
                      class="btn-radius responsive-btn-block"
                      @click="changePlan"
                    >
                      CHANGE PLAN
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-show="!planSelected && isShowingPlans"
          cols="12"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              class="text-center"
            >
              <h6 class="heading heading-m-small mb-2">
                Changing my plan
              </h6>
            </v-col>
            <v-col
              cols="12"
              class="text-center"
            >
              <p
                v-show="userSubscription && userSubscription.stripeId === null"
                class="text text-l-small-regular"
              >
                Currently, you are subscribed to a free plan.
                To get your {{ freePlanCredits }} unawarded CME credits, you should choose a pro plan or refer users to use the app.
              </p>
              <p
                v-show="userSubscription && userSubscription.stripeId !== null"
                class="text text-l-small-regular"
              >
                Currently, you are subscribed to the {{ userSubcriptionPlanName }} plan.
                You can change your subscription below.
              </p>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            justify-sm="center"
            class="horizontal-scroll-container"
          >
            <PlanCardComponent
              v-for="plan,key in plans"
              :key="key"
              :plan="plan" 
              :is-user-free-plan="isUserFreePlan"
              :prices-hash="{ 'standard': plans[enumPlans.standard].priceHash, 'professional': plans[enumPlans.professional].priceHash }"
              :current-plan="currentPlan"
              :current-plan-name="currentPlanName"
              :current-plan-end-at="userSubscriptionEndAt"
              :current-plan-cancelled-at="userSubscriptionCancelledAt"
              class="col-8 col-sm-4 col-md-4 my-3 mx-3 mx-sm-2 mx-md-2 horizontal-scroll-item d-flex"
              @select-plan="selectPlan"
              @change-success="changeSuccess"
            />
          </v-row>
        </v-col>
        <v-col
          v-show="planSelected && !isShowingPlans"
          cols="12"
          sm="7"
        >
          <v-row
            v-show="isUserFreePlan || isUpgradingPlan"
            no-gutters
            class="justify-center"
          >
            <v-col class="col-12 mb-5 text-center">
              <h6 class="heading heading-m-small">
                Upgrade to {{ planSelectedTitle }} plan
              </h6>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="justify-center mb-3"
          >
            <v-col
              cols="12"
              class="d-flex text-center justify-center align-center"
            >
              <div class="d-flex align-center">
                <v-sheet class="card-comparison-current-plan card-comparison-plan pa-3">
                  <v-row
                    no-gutters
                    class="text-center"
                  >
                    <v-col
                      cols="12"
                      class="mb-1"
                    >
                      <div class="text text-l-small-bold card-comparison-title">
                        Current plan
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      class="mb-1"
                    >
                      <h6 class="heading heading-m-small">
                        {{ userSubcriptionPlanName }}
                      </h6>
                    </v-col>
                    <v-col cols="12">
                      <span class="heading display-small plan-price-big">${{ userSubscriptionPriceMonthly }}</span>
                    </v-col>
                    <v-col cols="12">
                      <span class="text text-l-large-regular plan-price-monthly-preview">Monthly</span>
                    </v-col>
                    <v-col
                      cols="12"
                      class="mb-2"
                    >
                      <span class="text text-l-small-regular plan-price-billed-preview">Billed anually at ${{ userSubscriptionBilledAnually }}</span>
                    </v-col>
                  </v-row>
                </v-sheet>
                <div>
                  <ArrowRightThinComponent />
                </div>
              </div>
              <div>
                <v-sheet class="card-comparison-plan px-3 py-8">
                  <v-row
                    no-gutters
                    class="text-center"
                  >
                    <v-col
                      cols="12"
                      class="mb-1"
                    >
                      <div class="text text-l-small-bold card-comparison-title">
                        New plan
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      class="mb-1"
                    >
                      <h6 class="heading heading-m-small">
                        {{ planSelectedTitle }}
                      </h6>
                    </v-col>
                    <v-col cols="12">
                      <span class="heading display-small plan-price-big">${{ planSelectedPriceMonthly }}</span>
                    </v-col>
                    <v-col cols="12">
                      <span class="text text-l-large-regular plan-price-monthly-preview">Monthly</span>
                    </v-col>
                    <v-col
                      cols="12"
                      class="mb-2"
                    >
                      <span class="text text-l-small-regular plan-price-billed-preview">Billed anually at ${{ planSelectedBilledAnually }}</span>
                    </v-col>
                  </v-row>
                </v-sheet>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="mb-1"
            >
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="mb-1"
                >
                  <h6 class="heading heading-m-small">
                    Payment details
                  </h6>
                </v-col>
                <v-col cols="12">
                  <p class="text text-l-small-regular plan-description-preview">
                    You will be charged today and on future renewal dates. You can cancel or upgrade your plan any time from your profile
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="currentPlan"
            no-gutters
            class="mb-4"
          >
            <v-col cols="12">
              <v-card class="card-box-payment">
                <v-row
                  no-gutters
                  class="text text-l-small-regular plan-prorate-details border-bottom-line py-4 px-4"
                >
                  <v-col
                    cols="8"
                    class="align-self-center"
                  >
                    <h4 class="">
                      {{ currentPlanName }} plan
                    </h4>
                    <p class="mb-0">
                      You have already paid
                    </p>
                  </v-col>
                  <v-col
                    cols="4"
                    class="text-right align-self-center"
                  >
                    <div class="pr-5">
                      $ {{ remainingAmount }}
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="text text-l-small-regular plan-prorate-details darker border-bottom-line py-4 px-4"
                >
                  <v-col
                    cols="8"
                    class="align-self-center"
                  >
                    <h4 class="">
                      {{ planSelectedTitle }} plan
                    </h4>
                    <p class="mb-0">
                      The charge for upgrading is
                    </p>
                  </v-col>
                  <v-col
                    cols="4"
                    class="text-right align-self-center"
                  >
                    <div class="pr-5">
                      $ {{ unusedAmount }}
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="text text-l-small-regular plan-prorate-details darker py-4 px-4"
                >
                  <v-col
                    cols="8"
                    class="align-self-center"
                  >
                    <h4 class="">
                      Total amount
                    </h4>
                  </v-col>
                  <v-col
                    cols="4"
                    class="text-right align-self-center"
                  >
                    <div class="text text-l-large-bold pr-5 primary--text">
                      $ {{ totalAmount }}
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card class="card-box-payment pa-4">
                <WrapperPaymentMethodComponent
                  :item-selected="planSelected"
                  @payment-success="subscriptionSuccess"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-show="!isShowingPlans && !planSelected"
          cols="12"
          sm="7"
          lg="6"
          class="mb-6"
        >
          <v-card class="card-box responsive-box  pa-4">
            <v-row
              no-gutters
              class="mb-2"
            >
              <v-col cols="12">
                <h6 class="heading heading-m-small">
                  My credits
                </h6>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              justify="center"
            >
              <v-col
                col
                sm="12"
                class="credits-box pa-2 mr-1 mr-sm-auto mb-sm-3"
              >
                <v-row no-gutters>
                  <v-col class="col-auto mr-2 no-line-height">
                    <ProcessedBadgeIconComponent
                      :width="29"
                      :height="32"
                    />
                  </v-col>
                  <v-col class="col-auto align-self-center">
                    <div class="text text-l-large-bold credits-text mb-1">
                      {{ awardedCreditsFormat }} CME
                    </div>
                    <div
                      v-show="hasUnawardedCredits && !isLargeDevice"
                      class="mb-1 corto"
                    >
                      <v-chip class="text text-m-small-regular awarded-chip">
                        <v-icon class="icon-credits">
                          mdi-check-circle-outline
                        </v-icon><span class="ml-2">Awarded</span>
                      </v-chip>
                    </div>
                  </v-col>
                  <v-col
                    v-show="isLargeDevice || (!hasUnawardedCredits && !isLargeDevice)"
                    class="text-right align-self-center largo"
                  >
                    <div class="mb-1">
                      <v-chip class="text text-m-small-regular awarded-chip">
                        <v-icon class="icon-credits">
                          mdi-check-circle-outline
                        </v-icon><span class="ml-2">Awarded</span>
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-show="hasUnawardedCredits"
                col
                sm="12"
                class="credits-box pa-2 ml-1 ml-sm-auto"
              >
                <v-row no-gutters>
                  <v-col class="col-auto mr-2 no-line-height">
                    <UnawardedBadgeIconComponent
                      :width="29"
                      :height="32"
                    />
                  </v-col>
                  <v-col class="col-auto align-self-center">
                    <div class="text text-l-large-bold credits-text mb-1">
                      {{ unawardedCreditsFormat }} CME
                    </div>
                    <div
                      v-show="!isLargeDevice"
                      class="mb-1"
                    >
                      <v-chip class="text text-m-small-regular unawarded-chip">
                        <v-icon class="icon-credits">
                          mdi-alert-circle-outline
                        </v-icon><span class="ml-2">Unawarded</span>
                      </v-chip>
                    </div>
                  </v-col>
                  <v-col
                    v-show="isLargeDevice"
                    class="text-right align-self-center"
                  >
                    <div class="mb-1">
                      <v-chip class="text text-m-small-regular unawarded-chip">
                        <v-icon class="icon-credits">
                          mdi-check-circle-outline
                        </v-icon><span class="ml-2">Unawarded</span>
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="mt-2"
            >
              <v-col cols="12">
                <p class="text text-m-small-regular plans-suggestion mb-0 mr-1">
                  {{ plansBottomCreditText }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          v-show="!isShowingPlans && !planSelected"
          cols="12"
          sm="7"
          lg="12"
        >
          <v-card class="card-box pa-4">
            <ReferralComponent :is-user-free-plan="isUserFreePlan" />
          </v-card>
        </v-col>
      </v-row>
      <v-row v-show="!finishInitLoad">
        <v-col cols="12">
          <ProgressBarComponent :title="''" />
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import WrapperPaymentMethodComponent from "@/components/payment/WrapperPaymentMethodComponent";
import PlanCardComponent from "@/components/subscriptions/PlanCardComponent";
import ReferralComponent from "@/components/subscriptions/ReferralComponent";
import ProcessedBadgeIconComponent from "@/assets/ProcessedBadgeIconComponent";
import UnawardedBadgeIconComponent from "@/assets/UnawardedBadgeIconComponent";
import ProgressBarComponent from "@/components/common/ProgressBarComponent";
import ArrowRightThinComponent from "@/assets/ArrowRightThinComponent";
import { mapActions, mapGetters } from 'vuex';
export default {
  components: { WrapperPaymentMethodComponent, PlanCardComponent, ReferralComponent, ProcessedBadgeIconComponent, UnawardedBadgeIconComponent, ProgressBarComponent, ArrowRightThinComponent },
  data: () => ({
    isShowingPlans: false,
    finishInitLoad: false,
    stripe: null,
    intentClientSecret: null,
    planSelected: null,
    currentPlan: null,
    currentPlanName: 'Free',
    currentPlanPriceMonthly: 0,
    currentPlanBilledAnually: 0,
    userSubscription: null,
    cancelledAt: null,
    freePlanCredits: 5.0,
    remainingAmount: 0,
    unusedAmount: 0,
    totalAmount: 0,
    plans: [
      {id: 0, title: 'Free', period: 'Lifetime subscription', creditsDetail: 'Maximum 5.0 CME', description: 'Plan description: met minim mollit non deserunt ullamco est sit aliqua dolor do amet.', priceMonthly: '0', billedAnually: '0', priceHash: null,
      benefits: ['Up to 5 CME or MOC credits', 'Use of referrals'], limitations: ['Unlimited credits CME']},
      {id: 1, title: 'Standard', period: '1 year subscription', creditsDetail: 'Unlimited credits CME', description: 'Plan description: met minim mollit non deserunt ullamco est sit aliqua dolor do amet.', priceMonthly: '21', billedAnually: '125', priceHash: 'price_1KGC3LKI375MnqqyE7x7k03x',
      benefits: ['Up to 50 CME or MOC credits per year', 'Use of referrals'], limitations: ['Unlimited credits CME']},
      {id: 2, title: 'Professional', period: '3 years subscription', creditsDetail: 'Unlimited credits CME', description: 'Plan description: met minim mollit non deserunt ullamco est sit aliqua dolor do amet.', priceMonthly: '14', billedAnually: '200', priceHash: 'price_1KGC5dKI375MnqqyK0HIUydK',
      benefits: ['Up to 100 CME or MOC credits per year', 'Use of referrals', 'Unlimited credits CME'], limitations: []},
    ],
    enumPlans: { free: 0, standard: 1, professional: 2 }
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    badgesWidth () {
      return this.$vuetify.breakpoint.mdAndUp ? 58 : 29;
    },
    badgesHeight () {
      return this.$vuetify.breakpoint.mdAndUp ? 64 : 32;
    },
    isUserFreePlan() {
      return this.userSubscription?.stripeId === null || this.userSubscription?.stripeId === undefined || this.userSubscription?.status === "canceled";
    },
    isUpgradingPlan() {
      return this.planSelected?.id === this.enumPlans.professional;
    },
    userSubcriptionPlanName() {
      return this.currentPlanName;
    },
    userSubscriptionEndAt() {
      return this.userSubscription?.endAt;
    },
    userSubscriptionCancelledAt() {
      return this.userSubscription?.cancelledAt;
    },
    userSubscriptionPriceMonthly() {
      return this.currentPlanPriceMonthly;
    },
    userSubscriptionBilledAnually() {
      return this.currentPlanBilledAnually;
    },
    planSelectedTitle() {
      return this.planSelected?.title;
    },
    planSelectedPriceMonthly() {
      return this.planSelected?.priceMonthly;
    },
    planSelectedBilledAnually() {
      return this.planSelected?.billedAnually;
    },
    planSelectedPeriod() {
      return this.planSelected?.period;
    },
    planSelecteDetailDescription() {
      return this.planSelected?.description;
    },
    awardedCreditsFormat() {
      return (this.currentUser?.awardedCredits.total/1).toFixed(1);
    },
    unawardedCreditsFormat() {
      return (this.currentUser?.unawardedCredits.total/1).toFixed(1);
    },
    hasUnawardedCredits() {
      return this.currentUser?.unawardedCredits.total > 0;
    },
    plansBottomCreditText() {
      if (this.currentUser?.stripe_id) {
        return "Awarded this calendar year";
      } 

      if (this.currentUser?.unawardedCredits.total !== 0) {
        return "Get a Standard or a Professional plan, or make a referral to get your unawarded credits";
      }
      
      return "";
    },
    isLargeDevice() {
      return this.$vuetify.breakpoint.smAndUp;
    }
  },
  methods: {
    ...mapActions('subscriptions', ['getUserSubscription', 'getSubscriptionChangePreview']),
    selectPlan(plan) {

      this.planSelected = plan;
      this.isShowingPlans = false;

      if(this.currentPlan) {
        this.subscriptionChangePreview(plan.priceHash);
      }
    },
    changeSuccess() {
      this.getSubscription();
    },
    subscriptionSuccess(subscription) {

      this.userSubscription = subscription.subscription;
      this.userSubscription.stripeId = subscription.subscription.stripe_id;
      this.currentPlan = this.planSelected.priceHash;
      this.currentPlanName = this.getPlanValueByHash(this.planSelected.priceHash, 'title');
      this.currentPlanPriceMonthly = this.getPlanValueByHash(this.planSelected.priceHash, 'priceMonthly');
      this.currentPlanBilledAnually = this.getPlanValueByHash(this.planSelected.priceHash, 'billedAnually');

      this.planSelected = null;
      this.isShowingPlans = false;
    },
    getSubscription() {
      this.getUserSubscription().then( response => {

        this.userSubscription = response.data;
        this.currentPlan = this.userSubscription.priceHash;
        this.currentPlanName = this.getPlanValueByHash(this.userSubscription.priceHash, 'title');
        this.currentPlanPriceMonthly = this.getPlanValueByHash(this.userSubscription.priceHash, 'priceMonthly');
        this.currentPlanBilledAnually = this.getPlanValueByHash(this.userSubscription.priceHash, 'billedAnually');

        this.finishInitLoad = true;
      });
    },
    async subscriptionChangePreview(plan) {
      let response = await this.getSubscriptionChangePreview({ params: { item: plan } });

      if (response.totalAmountToPaid !== undefined) {
        this.remainingAmount = response.remainingTotalAmount;
        this.unusedAmount = response.unusedTotalAmount;
        this.totalAmount = response.totalAmountToPaid;
      } else {
        this.$toasted.global.showError({message:  response.data ?  response.data.message : 'Unexpected error'});
      }
    },
    changePlan() {
      this.isShowingPlans = true;
      this.focusOnCurrentUserSubscription();
    },
    getPlanValueByHash(hashPrice, field) {

      const isStandardPlan = hashPrice === this.plans[this.enumPlans.standard].priceHash;
      const isProfessionalPlan = hashPrice === this.plans[this.enumPlans.professional].priceHash;

      if (isStandardPlan) {
          return this.plans[this.enumPlans.standard][field];
      } else if (isProfessionalPlan) {
          return this.plans[this.enumPlans.professional][field];
      } else {
        return this.plans[this.enumPlans.free][field];
      }
    },
    focusOnCurrentUserSubscription() {

      let intViewportWidth = window.innerWidth;
      var distanceToSwipe = 0;

      switch (this.currentPlanName) {
        case 'Free':
          distanceToSwipe = 0;
          break;
        case 'Standard':
          distanceToSwipe = Math.floor((intViewportWidth / 2) + (intViewportWidth*0.05));
          break;
        case 'Professional':
          distanceToSwipe = Math.floor(intViewportWidth + (intViewportWidth*0.3));
          break;
      }

      setTimeout(function(){
          document.querySelector('.horizontal-scroll-container').scrollLeft = distanceToSwipe;
      },10);

    },
  },
  mounted() {
    this.getSubscription();
  }
}
</script>

<style scoped>
.view-container {
  display: flex;
}
div.v-card.card-box {
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.07) !important;
  border-radius: 16px;
}
.credits-box {
  background-color: var(--v-background-base);
  border-radius: 10px;
}
.credits-text {
  color: #171725;
}
.btn-close-mark {
  color: #B0BEC5 !important;
}
.horizontal-scroll-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}
.horizontal-scroll-container::-webkit-scrollbar {
  display: none;
}
.horizontal-scroll-item {
  flex: 0 0 auto;
}
.plan-prorate-details {
  color: #757575;
}
.plan-prorate-details.darker {
  color: #000000;
}
.plans-suggestion {
  text-align: right;
  color: #757575;
}
@media (min-width: 810px) and (max-width: 1024px) {
  .horizontal-scroll-item {
    width: 225px;
  }
}
@media (min-width: 960px) {
  .horizontal-scroll-item {
    width: 225px;
  }
  .horizontal-scroll-container {
    scrollbar-width: 0;
  }
  .responsive-box {
    height: 235px !important;
  }
  .responsive-btn-block {
    min-width: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.card-comparison-title {
  color: #757575;
}
.card-comparison-current-plan {
  border: 1px solid #1DA1F2;
}
.card-comparison-plan {
  min-width: 142px;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
}
</style>
<style>
.btn-choose-plan {
  border-radius: 10px;
}
div.v-card.card-box-plan {
  display: flex;
  border-radius: 16px;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.07) !important;
}
div.v-card.card-box-payment {
  border-radius: 16px;
  box-shadow: none !important;
}
div.v-card.card-box-plan-selected {
  border: 1px solid #1DA1F2;
}
.plan-price-big {
  color: #1DA1F2;
}
.plan-price-monthly-preview {
  color: #000000;
}
.plan-price-billed-preview {
  color: #424242;
}
.plan-price-pill-preview {
  background: #D2ECFC;
  border-radius: 12px;
}
.plan-price-pill-text-preview {
  text-transform: uppercase !important;
  color: #1DA1F2;
}
.plan-description-preview {
  color: #757575;
}
.plan-current {
  color: #1DA1F2;
}
.plan-current-subtitle {
  color: #616161;
}
.sheet-panel {
  background: #FAFAFA;
  border-radius: 8px;
}
.plan-bullet-text {
  margin-top: 2px;
  color: #424242;
}
.border-bottom-line {
  border-bottom: 1px solid #dcd9d9;
}
.awarded-chip {
  color: #212121;
  background: #DCFFE4 !important;
  border-radius: 10px !important;
  width: 112px;
}
.awarded-chip .icon-credits {
  color: #3DD598;
}
.unawarded-chip {
  color: #212121;
  background: #FFF8E1 !important;
  border-radius: 10px !important;
  width: 112px;
}
.unawarded-chip .icon-credits {
  color: #FB8C00;
}
.icon-credits {
  font-size: 20px !important;
}
.no-line-height {
  line-height: 0 !important;
}
</style>