<template>
  <div>
    <v-row no-gutters> 
      <v-col cols="12">
        <h6 class="heading heading-m-small">
          Referral credits
        </h6>
      </v-col>
      <v-col cols="12">
        <p class="text text-m-large-regular">
          {{ description }}
        </p>
      </v-col>
      <v-col cols="12">
        <ShareComponent
          :title="'Acapedia'"
          :description="'Signup here in Acapedia!'"
          :url="urlToShareReferralLink"
          :initial-share-counter="null"
          :enable-copy-to-clipboard="false"
          :btn-style="true"
        >
          <template #content>
            <span>Make a referral</span>
          </template>
        </ShareComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ShareComponent from "@/components/common/ShareComponent";
import { mapGetters } from 'vuex';
export default {
  components: { ShareComponent },
  props: ['isUserFreePlan'],
  computed: {
    ...mapGetters('profile', ['currentProfile']),
    description() {
      return this.isUserFreePlan ?
      "Get 0.5 CME credits by referring a contact to use the app. You will be awarded after the user creates their account and takes their first quiz.":
      "If you are enjoying Acapedia, you can make a referral.";
    },
    urlToShareReferralLink() {
      return `${process.env.VUE_APP_URL}/register?referralCode=${this.currentProfile.referralCode}`;
    },
  },
}
</script>
